import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Shell from '../components/global/shell'
import Container from '../components/global/container'
import styled from 'styled-components'


const Apps = (props) => {

  return (
    <Shell className="bg-gray-100">
      <div className="bg-gray-100">
      <Container>

        <h2 className="text-center font-bold text-lg md:text-3xl pt-10 pb-3 text-gray-800">Educational Apps</h2>
        <p className="w-full md:w-1/3 text-center md:text-lg text-gray-500 mx-auto mb-10">A list of educational apps that make it easy to create data visualizations in ggplot2.</p>
      </Container>

      <Container>

        <div className="flex flex-row flex-wrap">

          <div className="mr-5 mb-10 rounded">
            <h3 className="mb-2 text-grey-800 text-base text-center">Aesthetics finder</h3>
            <Link to="/aesthetics"><StaticImage src="../images/apps/aesthetics2.PNG" className="w-100 lg:w-96 shadow hover:shadow-md rounded" /></Link>
          </div>

          <div className="mr-5 mb-10">
            <h3 className="mb-2 text-grey-800 text-base text-center">Scales finder</h3>
            <Link to="/scales"><StaticImage src="../images/apps/scales2.PNG" className="w-100 lg:w-96 shadow hover:shadow-md rounded" /></Link>
          </div>

          <div className="mr-5 mb-10">
            <h3 className="mb-2 text-grey-800 text-base text-center">Theme finder</h3>
            <Link to="/theme"><StaticImage src="../images/apps/theme2.PNG" className="w-100 lg:w-96 shadow hover:shadow-md rounded" /></Link>
          </div>

        </div>
        
      </Container>
      </div>
    </Shell>
  )
}

export default Apps


